(function () {
  if (window._fitAnalytics) return;

  window._fitAnalytics = function (imports) {
    const { _, $, Integration, AddOn } = imports;

    const processLang = (lang) => (lang == 'gl' ? 'es' : lang);

    class TranslationHandler extends AddOn {
      onSizeRecommendFinished(scope, data = {}) {
        this.recommend(scope, data.sizeCode, data.details);
      }
      onWidgetClose(scope, serial, sizeCode, details) {
        this.recommend(scope, sizeCode, details);
      }

      onWidgetCart(scope, serial, sizeCode, details) {
        this.recommend(scope, sizeCode, details);
      }

      recommend(scope, sizeCode, details = {}) {
        var isValidSize = !!(
          typeof sizeCode === 'string' &&
          !details.outOfStock &&
          !details.outOfScale
        );
        const spanishTexts = {
          gl: ['Atopa o teu talle', 'O meu talle é']
        };
        if (spanishTexts[fitAnalyticsData?.shopLanguage]) {
          if (isValidSize) {
            let trials = 50;
            const interval = setInterval(() => {
              if (
                $('.fitanalytics__button-text')
                  .text()
                  .includes(scope.props.button?.text?.es[1].replace('{{size}}', ''))
              ) {
                $('.fitanalytics__button-text').text(
                  spanishTexts[fitAnalyticsData?.shopLanguage][1] + ' ' + sizeCode
                );
                clearInterval(interval);
              } else if (--trials === 0) {
                clearInterval(interval);
              }
            }, 50);
          } else {
            let trials = 50;
            const interval = setInterval(() => {
              if (
                $('.fitanalytics__button-text').text().includes(scope.props.button?.text?.es[0])
              ) {
                $('.fitanalytics__button-text').text(
                  spanishTexts[fitAnalyticsData?.shopLanguage][0]
                );
                clearInterval(interval);
              } else if (--trials === 0) {
                clearInterval(interval);
              }
            }, 50);
          }
        }
      }
    }

    let translationHandler = new TranslationHandler();

    const baseConfig = {
      debug: false,
      highlight: false,

      addOns: [translationHandler],

      shop: {
        node: window,
        prefix: 'bimba_lola',
        shopLanguage: {
          path: 'fitAnalyticsData.shopLanguage'
        },
        userLanguage: {
          path: 'fitAnalyticsData.shopLanguage',
          process: (lang) => processLang(lang)
        },
        country: {
          path: 'fitAnalyticsData.shopCountry'
        },
        userId: {
          path: 'fitAnalyticsData.userId'
        },
        rootDomain: {
          node: window,
          path: 'location.hostname',
          process: /((sf-byl|bimbalola)\.[a-z\.]+$)/
        },
        shopSessionId: {
          path: 'fitAnalyticsData.shopSessionId'
        },
        isReady: () => Boolean(window.fitAnalyticsData)
      },

      product: {
        container: '.the-product-details',

        hasManufacturedSizes: true,

        serials: {
          node: window,
          path: 'fitAnalyticsData.allItemSubgroupIds'
        },

        currentSerial: {
          node: window,
          path: 'fitAnalyticsData.currentItemSubgroupId'
        },

        thumbnail: {
          node: window,
          path: 'fitAnalyticsData.mainImageLink'
        },

        addToCart: (serial, sizeCode) => {
          if (fitAnalyticsData?.operations?.addToCart) {
            fitAnalyticsData.operations.addToCart(fitAnalyticsData.currentItemSubgroupId, sizeCode);
          }
        },

        sizes: {
          node: window,
          items: {
            path: 'fitAnalyticsData.sizes'
          },
          code: {
            path: 'value'
          },
          isAvailable: {
            path: 'isAvailable'
          },
          shouldSelectRecommended: false
        }
      },

      button: {
        text: {
          de: ['Finde deine Größe', 'Ich trage Größe {{size}}'],
          en: ['Find your size', 'I take size {{size}}'],
          es: ['Encuentra tu talla', 'Mi talla es {{size}}'],
          ca: ['Troba la teva talla', 'La meva talla és {{size}}'],
          fr: ['Trouver ma taille', 'La taille {{size}} est ma taille'],
          it: ['Trova la tua taglia', 'La mia taglia è la {{size}}'],
          pt: ['Encontrar seu tamanho', 'O meu tamanho é o {{size}}']
        },
        style: {
          button: {
            display: 'inline-block',
            'margin-top': '0.5rem',
            'text-align': 'left',
            'margin-bottom': '1.25rem'
          },
          image: {
            display: 'none'
          },
          text: {
            'font-family': 'FKGrotesk',
            'font-size': '0.875rem',
            'font-weight': '700',
            color: 'black',
            'text-decoration': 'underline',
            'text-transform': 'initial',
            'line-height': 1
          },
          ariaCSS: {
            outline: 'none'
          }
        },
        textAttrs: {
          onmouseover: 'this.style.textDecoration="none";return false;',
          onmouseleave: 'this.style.textDecoration="underline";return false;'
        }
      },

      sizeChartButton: {
        elem: {
          process: function () {
            var sizeChartElem = _.lookup(
              window,
              'fitAnalyticsData.operations.getSizeChartLink',
              () => {}
            )();
            return sizeChartElem;
          }
        },
        shouldHide: false
      }
    };

    const desktopConfig = {
      key: 'desktopConfig',
      variantType: 'primary',
      button: {
        anchor: '.the-product-details__sizes',
        anchorPlacement: 'after'
      }
    };

    const mobileConfig = {
      key: 'mobileConfig',
      variantType: 'primary',
      button: {
        anchor: '.the-product-details-size-selector',
        anchorPlacement: 'append',
        style: {
          button: {
            width: 'fit-content',
            'margin-top': '1rem'
          }
        },
        buttonAttrs: {
          tabindex: null
        }
      }
    };

    const integration = new Integration(baseConfig, [
      Integration.isMobile() ? mobileConfig : desktopConfig
    ]);

    window._fitAnalytics.integration = integration;
    integration.start();
  };

  function isMobile() {
    return (
      /iphone|android|iemobile|blackberry/i.test(window.navigator.userAgent) &&
      'ontouchstart' in window
    );
  }

  function init() {
    const scriptElem = document.createElement('script');
    scriptElem.setAttribute('src', '//widget.fitanalytics.com/widget_pdp.js');
    scriptElem.setAttribute('type', 'text/javascript');
    document.body.appendChild(scriptElem);

    if (isMobile()) {
      // add small divider before FA button on mobile
      const styleElem = document.createElement('style');
      styleElem.setAttribute('type', 'text/css');
      styleElem.innerText = `
      .fitanalytics__button::before {
        content: '';
        display: block;
        background-color: #cfcfcf;
        height: 1px;
        width: 40px;
        position: relative;
        top: -1rem;
      }
      `;
      document.head.appendChild(styleElem);
    }
  }

  if (document.readyState === 'interactive' || document.readyState === 'complete') {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', function () {
      init();
    });
  }
})();
